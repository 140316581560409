import axios from "axios";
import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";
 
const AuthContext = createContext( { userInfo: null } );

export const AuthContextProvider = ({ children }) =>
{
    console.log('baseURL: '+process.env.REACT_APP_BASEURL);
    console.log('authURL: '+process.env.REACT_APP_AUTHURL);
    const auth = axios.create(
    {
        // baseURL: process.env.REACT_APP_BASE_AUTH_ENDPOINT,
        // baseURL:    'https://auth.babalooey.com/api/v1/',
        baseURL:    process.env.REACT_APP_AUTHURL,
		headers:
		{
			"Content-Type":		"application/json",
		}
    });
	const api = axios.create(
	{
        baseURL:    process.env.REACT_APP_BASEURL,
		// baseURL:	'https://dev.babalooey.com/api/v1/',
        // baseURL: process.env.REACT_APP_BASE_API_ENDPOINT,
		headers:
		{
			"Content-Type":		"application/json"
		},
	});

	// Request interceptor to add Authorization header
	// api.interceptors.request.use((config) =>
    const interceptorAddAuth = (config) =>
    {
		let tokensData = JSON.parse(localStorage.getItem("tokens"));
		if (tokensData)
		{
			// console.log('tokensData:');
			// console.log(tokensData);
			// console.log(`Bearer ${tokensData.token}`);
			// console.log('config headers:');
			// console.log(config.headers.common);
			config.headers.Authorization = `Bearer ${tokensData.token}`;
		}
		return config;
    };
    api.interceptors.request.use(interceptorAddAuth);
    auth.interceptors.request.use(interceptorAddAuth);
    /*
	api.interceptors.request.use((config) =>
	{
		// console.log('tokens:');
		// console.log(localStorage.getItem("tokens"));
		let tokensData = JSON.parse(localStorage.getItem("tokens"));
		if (tokensData)
		{
			// console.log('tokensData:');
			// console.log(tokensData);
			// console.log(`Bearer ${tokensData.token}`);
			// console.log('config headers:');
			// console.log(config.headers.common);
			config.headers.Authorization = `Bearer ${tokensData.token}`;
		}
		return config;
	});
	auth.interceptors.request.use((config) =>
	{
		// console.log('tokens:');
		// console.log(localStorage.getItem("tokens"));
		let tokensData = JSON.parse(localStorage.getItem("tokens"));
		if (tokensData)
		{
			// console.log('tokensData:');
			// console.log(tokensData);
			// console.log(`Bearer ${tokensData.token}`);
			// console.log('config headers:');
			// console.log(config.headers.common);
			config.headers.Authorization = `Bearer ${tokensData.token}`;
		}
		return config;
	});
    */

	// Response interceptor to handle refresh tokens
    const interceptorAddRefresh = async (error) =>
    {
        console.log('interceptorAddRefresh() - Entering');
        const originalRequest = error.config;
		if (error && error.response && error.response.status === 401 && !originalRequest._retry)
		{
			try
			{
				originalRequest._retry = true;
				let tokensData = JSON.parse(localStorage.getItem("tokens"));
				const apiResponse = await auth.post(
					"token",
					{ refreshToken: tokensData.refreshToken }
				);
				//console.log(apiResponse);
				console.log('response interceptor - calling setItem(tokens) with ['+JSON.stringify(apiResponse.data['data'])+']');
				localStorage.setItem("tokens", JSON.stringify(apiResponse.data['data']));
	
				return auth(originalRequest);
			}
			catch (e)
			{
				console.log('error:');
				console.log(e);
				logout();
				window.location.href = '/';
			}
		}
		return Promise.reject(error);
    };
    api.interceptors.response.use((response) => { return response; }, interceptorAddRefresh);
    auth.interceptors.response.use((response) => { return response; }, interceptorAddRefresh);
    /*
	api.interceptors.response.use((response) =>
	{
		return response;
	}, async function (error) 
	{
		const originalRequest = error.config;
		if (error && error.response && error.response.status === 401 && !originalRequest._retry)
		{
			try
			{
				originalRequest._retry = true;
				let tokensData = JSON.parse(localStorage.getItem("tokens"));
				console.log('tokensData:');
				console.log(tokensData);
				const apiResponse = await auth.post(
					"token",
					{ refreshToken: tokensData.refreshToken }
				);
				//console.log(apiResponse);
				console.log('response interceptor - calling setItem(tokens) with ['+JSON.stringify(apiResponse.data['data'])+']');
				localStorage.setItem("tokens", JSON.stringify(apiResponse.data['data']));
	
				return auth(originalRequest);
			}
			catch (e)
			{
				console.log('error:');
				console.log(e);
				logout();
				window.location.href = '/';
			}
		}
		return Promise.reject(error);
  	});
    */

	const [user, setUser] = useState(null);
	const [tokens, setTokens] = useState(() =>
	{
		if (localStorage.getItem("tokens"))
		{
			try
			{
				const token = localStorage.getItem("tokens");
				const jsonval = JSON.parse(localStorage.getItem("tokens"));
				setUser(jwt_decode(jsonval.token));
				return jsonval;
			}
			catch (e)
			{
				localStorage.removeItem("tokens");
			}
		}
		return null;
	});

	const login = async (payload) =>
	{
		console.log('AuthContext::login() - ENTERING');
	    const apiResponse = await auth.post(
	    	// "https://auth.babalooey.com:4443/api/v1/login",
	    	// "http://localhost/api/v1/users",
	    	"login",
	    	payload
	    );
        console.log('login() - calling setItem(tokens) with ['+JSON.stringify(apiResponse.data['data'])+']');
        localStorage.setItem("tokens", JSON.stringify(apiResponse.data['data']));
        setUser(apiResponse.data['data']);
        // setUser(jwt_decode(apiResponse.data.access_token));
        // navigate("/");
        return apiResponse;
	};

	const logout = async () =>
	{
		console.log('AuthContext::logout() - ENTERING');
		// Clear menu

		// invoke the logout API call, for our NestJS API no logout API
		localStorage.removeItem("tokens");
		setUser(null);
		window.location.href='/';
	};

    const extra = {};

	return (
		<AuthContext.Provider value={{ api, user, login, logout, extra }}>
			{children}
		</AuthContext.Provider>
	);
	/*
	return (
		<AuthContext.Provider>
			{children}
		</AuthContext.Provider>
	)
	*/
};

export default AuthContext;