import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';

import '../CSS/Sidebar.css';

// export default ({ menu }) =>
// function Sidebar({menu})
function Sidebar(props)
{
	const menu = props.menu;
	// console.log('Sidebar() - ENTERING');

	const [menuItems, setMenuItems] = useState({});


	// Handle isMobile?
	const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);
	const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);
	useEffect(() =>
	{
		const handleResize = () =>
		{
			setIsMobile(window.matchMedia('(max-width: 768px)').matches);
			setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
			// setIsMobile(window.innerWidth <= 768);
		};
		window.addEventListener('resize', handleResize);

		return () =>
		{
		  window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() =>
	{
		// console.log('Sidebar::useEffect() - HERE');
		// console.log(menu);
		// const sorted = Object.keys(menu).sort((a, b) => menu[a]['order'] - menu[b]['order']);
		// console.log('sorted:', sorted);
		setMenuItems(menu);
	}, [menu]);


	const renderMenu = (menuItems) =>
	{
		const sortFunction = (a,b) =>
		{
			// console.log('sortFunction: comparing a ['+a+'] & b: ['+b+']', a, b);
			// console.log('a: ', menuItems[a]);
			// console.log('b: ', menuItems[b]);
			const aval = menuItems[a] && menuItems[a].order ? menuItems[a].order : 0;
			const bval = menuItems[b] && menuItems[b].order ? menuItems[b].order : 0;
			// console.log('aval: '+aval+' / bval: '+bval);
			// console.log('returning: '+(aval - bval));
			return aval - bval;
			// if (menuItems[a] &&  menuItems[a].order && menuItems[b] && menuItems[b].order)
				// return menuItems[a].order - menuItems[b].order;
			// return 0;
		}
		// console.log('Sidebar::renderMenu() - ENTERING');
		// console.log(menuItems);
		if (menuItems === undefined)
			menuItems = {};

		/*
		const renderItems = Object.keys(menuItems).sort((a, b) =>
		{
			console.log('a (items): ', menuItems[a]);
			console.log('b (items): ', menuItems[b]);
			const aval = menuItems[a] && menuItems[a].order ? menuItems[a].order : 0;
			const bval = menuItems[b] && menuItems[b].order ? menuItems[b].order : 0;
			return aval - bval;
			// return menuItems[b].order - menuItems[a].order;
			return 0;
		})*/
		// console.log('renderItems: ');
		// console.log(renderItems);
		return (
			<ul>
				{/* {Object.keys(menuItems).map((menuItem, index) => ( */}
				{Object.keys(menuItems).sort(sortFunction).map((menuItem, index) => (
					<li key={index} onClick={menuItems[menuItem]['function'] ? menuItems[menuItem]['function'] : undefined}>
						{(menuItems[menuItem].link || menuItems[menuItem].function) ? (
							<a href={menuItems[menuItem].link}>{menuItem}</a> 
						) : (
							<span>{menuItem}</span>
						)}
						{menuItems[menuItem].children && renderMenu(menuItems[menuItem].children)}
					</li>
				))}
			</ul>);
	};
	  
	// return <div className="Joe">{renderMenu(menuItems)}</div>
	// return <div className="Joe"><Menu className="sidebar">{renderMenu(menuItems)}</Menu></div>
    if (isMobile)
	    return (menuItems !== undefined && typeof menuItems === 'object' && Object.keys(menuItems).length === 0) ? "" : <Menu className="sidebar">{renderMenu(menuItems)}</Menu>
    else
        return (menuItems !== undefined && typeof menuItems === 'object' && Object.keys(menuItems).length === 0) ? "" : <div className='sidebarWrapper'><div className="bm-menu"><div className='logo'></div>{renderMenu(menuItems)}</div></div>
        // return (menuItems !== undefined && typeof menuItems === 'object' && Object.keys(menuItems).length === 0) ? "" : <Menu className="sidebar" isOpen noTransition customBurgerIcon={ false } customCrossIcon={ false } >{renderMenu(menuItems)}</Menu>
};

export default Sidebar;