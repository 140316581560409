import React from 'react';
import DeptGroupShift from './DeptGroupShift';
// import DeptGroupAvail from './DeptGroupAvail';

import '../CSS/DeptGroupDate.css';


function DeptGroupDate(props)
{
    const objectDate = props.date;

    // console.log('Have '+(Object.keys(props.shifts).length)+' shifts for '+props.keyName+'.');

    let shifts = [];
    if (props['shifts'] !== undefined && (Object.keys(props.shifts).length))
    {
        // Pull associative (object) array props.shifts and store it in an array that we can sort
        let propsShifts = [];
        for (const shiftid in props.shifts)
            propsShifts.push(props.shifts[shiftid]);
        const shiftsSorted = propsShifts.sort((a, b) =>
        {
            if (a.shift_start < b.shift_start)
                return -1;
            if (a.shift_start > b.shift_start)
                return 1;
            if (a.shift_end < b.shift_end)
                return -1;
            if (a.shift_end > b.shift_end)
                return 1;
            return 0;
        });

        shiftsSorted.map((shift) =>
        {
            shifts.push(<DeptGroupShift key={'shift-'+(shift.shiftid || 0)} className='dgshift' shift={shift} roles={props.roles} parentItems={props.parentItems} />);
        })
    }
    return (
    <div key={'dgdate-'+objectDate.yyyymmdd()} className='dgdate'>
        <div className='dgdateheader'>{getDateString(objectDate)}</div>
        <div className='dgdateshifts'>
            { shifts }
        </div>
    </div>);
}

export default DeptGroupDate;

function calTimeString(s,e)
{
    return getTime(s)+' - '+getTime(e);
}

function stringToDate(i)
{
    // Added handling of i being a Date object on 20170220...
    if ((i instanceof Date))
        return i;
    if (typeof(i) !== 'string' && !(i instanceof String))
        return new Date();
    var d = i.match(/(\d+)/g);
    return new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]);
}

function getDateString(d)
{
    var dow = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var moy = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var y = new Date();
    return dow[d.getDay()] + ' '+moy[d.getMonth()]+' '+d.getDate()+((d.getFullYear() == y.getFullYear()) ? '' : ', '+d.getFullYear());
    // return moy[d.getMonth()]+' '+d.getDate()+((d.getFullYear() == y.getFullYear()) ? '' : ', '+d.getFullYear()) + ' ('+dow[d.getDay()] + ')';
}

function formatDateDuration(s, e, ds)
{
    if (ds === undefined)
        ds = "D, M j ";
    var start = stringToDate(s);
    var startDate = getDateString(start);
    var startTime = getTime(start);
    var end = stringToDate(e);
    var endDate = getDateString(end);
    var endTime = getTime(end);
    var durString = startDate + ' ' + startTime + ' -';
    if (startDate !== endDate)
        durString += ' ' +  endDate;
    durString += ' ' + endTime;
    return durString;
}

function getTime(e, rawtime)
{
    if (rawtime === true)
        return ("0" + e.getHours()).slice(-2) + ("0" + e.getMinutes()).slice(-2);
    var ap;
    var d = new Date(e);
    if (d.getHours() > 12)
        return (d.getHours() - 12)+':'+("0"+d.getMinutes()).slice(-2)+'pm';
    else if (d.getHours() == 12)
    {
        if (d.getMinutes() == 0)
            return "Noon";
        return "12:"+("0"+d.getMinutes()).slice(-2)+'pm';
    }
    else if (d.getHours())
        return d.getHours()+':'+("0"+d.getMinutes()).slice(-2)+'am';
    if (d.getMinutes() == 0)
        return "Midnight";
    return '12:'+("0"+d.getMinutes()).slice(-2)+'am';
}