import '../CSS/DeptPageMySchedule.css';

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// import parse, { attributesToProps } from 'html-react-parser';

import AuthContext from '../components/AuthContext';

import EventSelector from '../components/EventSelector';
import ShiftList from '../components/ShiftList';

export default function DeptPageMySchedule(props)
{
	const { api, user, extra }	 = useContext(AuthContext);
	const [ eventList, setEventList ] = useState({});
	const [ currentEvent, setCurrentEvent ] = useState({});
	const [ shiftList, setShiftList ] = useState([]);
    const [ deptInfo, setDeptInfo ] = useState({});
	const params = useParams();

	function setEventChange(eventid)
	{
		setCurrentEvent(eventid);
	}

	function sortFunction(a, b)
	{
		// console.log('sortFunction('+a.shift_start+', '+b.shift_start+') - ENTERING');
		const dateA = new Date(a.shift_start);
		const dateB = new Date(b.shift_start);
		return dateA.getTime() - dateB.getTime();
	}

	useEffect(() =>
	{
		if (!user)
			return;
		// list departments
		api.get(
			"app?page=myschedule&deptid="+params.id
		).then((result) =>
		{
			setEventList(result.data.data.events);
			setShiftList(result.data.data.myschedule);
            setDeptInfo(result.data.data.deptinfo);
            const newEventList = result.data.data.events;
			setCurrentEvent((newEventList.length === 1) ? newEventList[0] : newEventList.reduce((a, b) => a.eventstart > b.eventstart ? a : b));

		});
	}, []);

    useEffect(() =>
    {
        if (extra.deptPage && extra.deptPage.deptinfo && extra.deptPage.deptinfo.deptname)
            props.parentItems.setBannerText('Welcome to '+extra.deptPage.deptinfo.deptname);
    }, [ extra.deptPage ]);

	return (
		<div className='deptPageBody'>
			<EventSelector events={eventList} setEventChange={setEventChange} />
			<ShiftList shifts={shiftList.sort(sortFunction).filter((item) => item.eventid == currentEvent)} deptinfo={deptInfo} />
		</div>
	);
}