import React, { useContext, useEffect, useState } from 'react';

function ShiftList({shifts, deptinfo})
{
	// console.log('ShiftList component ENTERING');
	// console.log('length: '+shifts.length+' / count: '+shifts.count);
	if (!shifts || !shifts.length)
		return (<p><i>No shifts scheduled for this event.</i></p>);
	const cols = [ "Shift Date", "Shift Time", "Group", "Role", "Value", "Status" ];
    if (deptinfo && !(deptinfo.deptflags & 8))
        delete cols[4];
	let sumWorked = 0, sumFuture = 0;
	return (
        <div>
		<table className='deptPageMySchedule'>
			<thead>
				<tr>{cols.map((item) => (<th key={item}>{item}</th>))}</tr>
			</thead>
			<tbody>
			{shifts.map((item) =>
			{
				// console.log('shift_start: '+item.shift_start);
				// console.log('shift_start_tz: '+item.shift_start_tz);
				const date = new Date(item.shift_start.slice(0,-1));
				// const date = new Date(dateTZ.toISOString().slice(0,-1));
				const availStatus = getAvailStatus(item);
				let availClass = "";
				switch (availStatus)
				{
				case "didn't work":
					availClass = 'notworked';
					break;
				case "worked":
					sumWorked += item.shiftvalue;
					availClass = 'worked';
					break;
				case "confirmed": 
				case "unconfirmed":
					sumFuture += item.shiftvalue;
				case "pending":
					// availClass = 'pending';
                    availClass = availStatus;
					break;
				default:
					availClass = '';
				}
				const svClass = "sv-"+(availStatus == "didn't work" ? "notworked" : availStatus);
				return (
					<tr key={item.availid}>
						<td className='shiftDate'>{getDateString(date)}</td>
						<td className='shiftTime'>{getTime(item.shift_start.slice(0,-1))+' - '+getTime(item.shift_end.slice(0,-1))}</td>
						<td className='groupName'>{item.groupname}</td>
						<td className='roleDesc'>{item.roledesc}</td>
						{deptinfo && ((deptinfo.deptflags & 8) > 0) && <td className={svClass}>{item.shiftvalue}</td>}
						<td className={availClass}>{availStatus}</td>
					</tr>
				)
			})}
			</tbody>
		</table>
        {deptinfo && ((deptinfo.deptflags & 8) > 0) && <h3 className='totalCredits'>Total Shift Credits: <span>{sumWorked}</span> / <span>{sumWorked + sumFuture}</span></h3>}
        </div>
	);
}

function getAvailStatus(avail = {})
{
	if (!parseInt(avail.availid))
		return "unknown";
	// Has shift ended?
	// if (strtotime($avail["shift_end_tz"]) > time())
	let availStatus = "";
	if (new Date(avail.shift_end_tz).getTime() > Date.now())
	{
		// Shift hasn't ended yet..
		if ((new Date(avail.shift_start_tz)).getTime() > Date.now())
		{
			// Shift also hasn't started yet. Is it confirmed?
			if (avail.confirmed)
				availStatus = "confirmed";
			else
				availStatus = "unconfirmed";
		}
		else
		{
			// Shift has started. Must be in progress
			availStatus = "in progress";
		}
	}
	else
	{
		// Shift has ended..
		if (avail.rosterflags & 1)
			availStatus = "worked";
		else if (avail.rosterflags & 2)
			availStatus = "didn't work";
		else if (avail.rosterflags & 4|8)
			availStatus = "pending";
		else
			availStatus = "ended";
	}
	return availStatus;
}

function stringToDate(i)
{       
	// Added handling of i being a Date object on 20170220...
	if ((i instanceof Date))
		return i;
	if (typeof(i) !== 'string' && !(i instanceof String))
		return new Date();
	var d = i.match(/(\d+)/g);
	return new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]);
}


function getDateString(d)
{   
	var dow = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	var moy = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	var y = new Date();
	return dow[d.getDay()] + ', '+moy[d.getMonth()]+' '+d.getDate()+((d.getFullYear() == y.getFullYear()) ? '' : ', '+d.getFullYear());
}

function formatDateDuration(s, e, ds)
{
	if (ds === undefined)
		ds = "D, M j ";
	// var start = stringToDate(s);
	var start = new Date(s);
	var startDate = getDateString(start);
	var startTime = getTime(start);
	// var end = stringToDate(e);
	var end = new Date(e);
	var endDate = getDateString(end);
	var endTime = getTime(end);
	var durString = startDate + ' ' + startTime + ' -';
	if (startDate !== endDate)
		durString += ' ' +  endDate;
	durString += ' ' + endTime;
	return durString;
}

function getTime(e, rawtime)
{
	if (rawtime === true)
		return ("0" + e.getHours()).slice(-2) + ("0" + e.getMinutes()).slice(-2);
	var ap;
	var d = new Date(e);
	if (d.getHours() > 12)
		return (d.getHours() - 12)+':'+("0"+d.getMinutes()).slice(-2)+'pm';
	else if (d.getHours() == 12)
	{
		if (d.getMinutes() == 0)
			return "Noon";
		return "12:"+("0"+d.getMinutes()).slice(-2)+'pm';
	}
	else if (d.getHours())
		return d.getHours()+':'+("0"+d.getMinutes()).slice(-2)+'am';
	if (d.getMinutes() == 0)
		return "Midnight";
	return '12:'+("0"+d.getMinutes()).slice(-2)+'am';
}

export default ShiftList;