import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

// import Alert from '@mui/material/Alert';
// import Collapse from '@mui/material/Collapse';

import AuthContext from '../components/AuthContext';
// const { user } = useContext(AuthContext);

import '../CSS/DeptGroupAvails.css';

function DeptGroupAvails(props)
{
	const shift = props.shift || [];
    // const avails = props.avails || [];
	const [ avails, setAvails ] = useState(props.avails || []);
	const params = useParams();

	const { api } = useContext(AuthContext);

	function updateAvail(avail)
	{
		// console.log('updateAvail() - CALLED FOR:', avail);
		// console.log('avails:', avails);
		const newAvails = avails.map((oneAvail) => oneAvail.availid === avail.availid ? avail : oneAvail);
		// console.log('newAvails:');
		// console.log(newAvails);
		setAvails(newAvails);
	}
    function clickFunction(a)
    {
        console.log('clickFunction() - ENTERING');
        const availid = a.target.id.split('-')[1];
		console.log('availid: '+availid);
		console.log(avails);
        api.put(
			// "https://dev.babalooey.com:444/api/v1/calendar/"+params.groupid,
            process.env.REACT_APP_CALENDAR_ENDPOINT+params.groupid,
			{
				availid: availid,
				userid: (avails[availid].userid === -1 ? 0 : -1)
			}
		).then((result) =>
		{
			// console.log('got result:');
			// console.log(result.data);
			// console.log(avails);
			updateAvail(result.data.data);
		}).catch((error) =>
		{
			props.parentItems.addNotification('error', error.response.data.error.message);
		})
    }

    return (
        <ul className='dgavails' key={'dgavails-'+shift.shiftid}>
        { 
            avails.map((oneavail) =>
            {
                const userField = oneavail.nickname || oneavail.userid || 'OPEN';
                // const clickable = (userField === 'OPEN' && oneavail.permW) ? clickFunction : undefined;
				// const clickable = (oneavail.state === true || oneavail.state === -1) ? (e) => clickFunction(e, oneavail.state) : undefined; // WAS: user.userid
				const clickable = (((props.roles.get(oneavail.roleid).permW) && oneavail.userid !== 1) ? (e) => clickFunction(e) : undefined); // WAS: user.userid
                return (
                    <li id={ 'dgavails-'+oneavail.availid } key={ 'dgavails-'+oneavail.availid }>
                        {/* <span className='dgavails-role' title={oneavail.roledesc} >{ oneavail.rolename }</span> */}
                        <span className='dgavails-role' title={props.roles.get(oneavail.roleid).roledesc} >{ props.roles.get(oneavail.roleid).rolename }</span>
                        <span className={ 'dgavails-user' + (clickable ? ' dgavails-clickable' : '') } onClick={ clickable } id={ 'dgavail-'+oneavail.availid }>{ userField }</span>
                    </li>
                )
            })
        }
        </ul>
    )
}

export default DeptGroupAvails;