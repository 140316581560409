import React, { useContext, useEffect, useState } from 'react';
// import { defaultProps } from 'react-select/dist/declarations/src/Select';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../components/AuthContext';

import Calendar from '../components/Calendar';

export default function CalPage(props)
{
	const { api, login, user }	 = useContext(AuthContext);
	const [roles, setRoles] = useState({});
	const [calendar, setCalendar] = useState({});

	const params = useParams();

	// -----=====>>>>> props.parentItems.buildMenu('CalPage', { id: params.id, deptgroups: result.data.data.deptgroups });

	return (
		<div className="Calendar">
			{/* <Calendar menuCallback={setMenuItems} groupid={params.groupid} parentItems={props.parentItems} /> */}
			<Calendar menuCallback={props.parentItems.setMenu} groupid={params.groupid} parentItems={props.parentItems} />
		</div>
	);
}