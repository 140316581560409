import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css";
var Carousel = require('react-responsive-carousel').Carousel;

function parseText(text)
{
    let parsedText = document.createElement('textarea');
    parsedText.innerHTML = text;
    return parsedText.value;
}

function ConditionalCarousel({ depts = {} })
{
	console.log('ConditionalCarousel() - ENTERING');
	console.log(depts);
	// const isMobile = window.matchMedia('(max-width: 768px)').matches;
	const navigate = useNavigate();

	// Handle isMobile?
	const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);
	const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);
	useEffect(() =>
	{
		const handleResize = () =>
		{
			setIsMobile(window.matchMedia('(max-width: 768px)').matches);
			setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
			// setIsMobile(window.innerWidth <= 768);
		};
		window.addEventListener('resize', handleResize);

		return () =>
		{
		  window.removeEventListener('resize', handleResize);
		};
	}, []);

	const onChange = () =>
	{

	}
	const onClick = (index, item) =>
	{
		navigate('/dept/'+item.key);
	}

	console.log('isMobile? '+isMobile);
	console.log('isPortrait? '+isPortrait);
	console.log('raw (matchmedia):');
	console.log(window.matchMedia('(orientation: portrait)'));
	if (isMobile && isPortrait)
	{
		return (
			<Carousel showArrows={true} onChange={onChange} onClickItem={onClick} onClickThumb={onClick} showThumbs={false} >
			{
				depts.map((item) => (
					<div key={item.deptid}>
						<img className='deptimg' src={"https://babalooey-static.s3-us-west-2.amazonaws.com/CSS/depts/"+item.hash+"/Images/logo.jpg"} />
						<p className='deptname'>{parseText(item.deptname)}</p>
					</div>
				))
			}
			</Carousel>
		)
	}
	else
	{
		return (
			<div className='deptlist'>
			{
				depts.map((item) => (
					<Dept dept={item} key={item.deptid} onClick={() => { navigate('/dept/'+item.deptid) }}/>
				))
			}
			</div>
		)	
	}
}

const cssDept =
{
	backgroundRepeat:		'no-repeat',
	backgroundPosition:		'center',
	backgroundSize:			'cover',
	border: 				'solid 1px #BDC38F',
	display:				'inline-block',
	height:					'300px',
	marginBottom:			'20px',
	marginRight:			'20px',
	width:					'215px'
}

const cssDeptName = 
{
	backgroundColor:		'#BDC38F',
	color:					'#000000',
	fontWeight:				'bold',
	textAlign:				'center'
}

const Dept = (props) =>
{
	const dept = props.dept;
	const url =  "https://babalooey-static.s3-us-west-2.amazonaws.com/CSS/depts/"+dept.hash+"/Images/logo.jpg";
	return (
		<div className='dept' style={{...cssDept,backgroundImage: `url(${url})`}} onClick={props.onClick}>
			<div className='deptName' style={cssDeptName}>{parseText(dept.deptname)}</div>
		</div>
	)
}

export default ConditionalCarousel