import '../CSS/DeptPage.css';

import React, { useContext, useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';

import parse from 'html-react-parser';

import AuthContext from '../components/AuthContext';

export default function DeptPage(props)
{
	const { extra }	 = useContext(AuthContext);
	// const { api, login, logout, user, extra }	 = useContext(AuthContext);
    // const [ additionalCSS, setAdditionalCSS ] = useState([]);
	const [ body, setBody ] = useState("");
	// const params = useParams();

    useEffect(() =>
    {
        if (extra.deptPage)
        {
            if (extra.deptPage.deptinfo)
                props.parentItems.setBannerText('Welcome to '+extra.deptPage.deptinfo.deptname);
            if (extra.deptPage.banner)
                setBody(extra.deptPage.banner);
        }
    }, [ extra.deptPage, props.parentItems ]);

	return (
		<div className='deptPageBody'>{parse(body)}</div>
	);
}