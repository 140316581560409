import '../CSS/HomePage.css';

import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";

import AuthContext from '../components/AuthContext';

import DeptGroupShift from '../components/DeptGroupShift';
import ConditionalCarousel from '../components/ConditionalCarousel';
import { getDialogContentUtilityClass } from '@mui/material';

export default function HomePage(props)
{
	// props.parentItems.setBannerText('');
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [formData, setFormData] = useState({email: '', password: ''});
	const { api, auth, login, logout, user } = useContext(AuthContext);
	const navigate = useNavigate();

    const handleChange = (e) =>
    {
        setFormData(
        {
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) =>
    {
        e.preventDefault();
		const payload = JSON.stringify(formData);
		login(payload)
		.then((response) =>
		{
			console.log('submit returned:');
			console.log(response);
			/*
			if (response.data && response.data.data && response.data.token && response.data.refreshToken && response.data.status === 'ok')
			{
				setUser(response.data.data);
			}
			*/
		})
        .catch((error) =>
        {
            console.log('error caught');
            console.log(error);
            const newFormData = { ...formData, password: '' };
            console.log('newformData: ');
            console.log(newFormData);
            setFormData({ ...formData, password: ''});
            if (error && error.response && error.response.data)
                setErrorMessage(error.response.data);
        })

		/*
        const requestOptions = {
            method:     'POST',
            headers:    
            {
                'Content-Type':     'application/json'
            },
            body:       JSON.stringify(formData)
        }
        // console.log(formData);
        fetch('http://localhost/api/v1/users/', requestOptions)
        .then((response) =>
        {
            console.log('got response:');
            console.log(response);

        })
		*/

    }

	const selectDept = (e) =>
	{
		console.log('got:');
		console.log(e);
	}

	const [depts, setDepts] = useState([]);

    /*
    var keys    = [];
    var konami  = '38,38,40,40,37,39,37,39,66,65';
    useEffect(() =>
    {
        const kcTrigger = () =>
        {
            getEle.css('background-image', 'url("/Images/baba-retro1.png")'); }
        const processKeyDown = () =>
        {
            keys.push(e.keyCode); if (keys.toString().indexOf(konami) >= 0 ) { kcTrigger(); keys = []; } 
        };
        if (user)
            return;
        console.log('document: ', document);
    }, []);
    */

	useEffect(() =>
	{
        // props.parentItems.setBannerText('');

		if (!user)
			return;

		// list departments
		// const payload={ page: 'deptselect' };
		api.get(
			"app?page=deptselect"
			// payload
		).then((result) =>
		{
			setDepts(result.data.data);
		});
	}, [api, user]);

	if (user)
	{
		return (
			<ConditionalCarousel depts={depts} />
		)
		/*
		return (
			<div className='deptlist'>
			{
				depts.map((item) => (
					<Dept dept={item} key={item.deptid} onClick={() => { navigate('/dept/'+item.deptid) }}/>
				))
			}
			</div>
		)
		*/
	}
	else
	{
		return (
        	<div>
        	    <div className='header'></div>
        	    <form>
        	        <label>Email Address</label>
        	        <input autoFocus type='text' name='email' onChange={handleChange} value={formData.email} InputProps={{ autocapitalize: 'off' }} />
        	        <label>Password</label>
        	        <input type='password' name='password' onChange={handleChange} value={formData.password} / >
        	        <input onClick={handleSubmit} type='submit' value='Sign In' />
        	    </form>
                { errorMessage ? <div className='error'>{errorMessage}</div> : null }
        	</div>
    	)
	}
}

const cssDept =
{
	backgroundRepeat:		'no-repeat',
	backgroundPosition:		'center',
	backgroundSize:			'cover',
	border: 				'solid 1px #BDC38F',
	display:				'inline-block',
	height:					'300px',
	marginBottom:			'20px',
	marginRight:			'20px',
	width:					'215px'
}

const cssDeptName = 
{
	backgroundColor:		'#BDC38F',
	color:					'#000000',
	fontWeight:				'bold',
	textAlign:				'center'
}

const Dept = (props) =>
{
	const dept = props.dept;
	const url =  "https://babalooey-static.s3-us-west-2.amazonaws.com/CSS/depts/"+dept.hash+"/Images/logo.jpg";
	return (
		<div className='dept' style={{...cssDept,backgroundImage: `url(${url})`}} onClick={props.onClick}>
			<div className='deptName' style={cssDeptName}>{dept.deptname}</div>
		</div>
	)
}
{/* backgroundImage:	url('https://babalooey-static.s3-us-west-2.amazonaws.com/CSS/depts/'+dept.hash+'Images/logo.jpg') */}
