import '../CSS/Calendar.css';

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DeptGroup from './DeptGroup';
import AuthContext from '../components/AuthContext';

function Calendar(props)
{
	const parentItems = props.parentItems;
    const [event, setEvent] = useState({});
	const [deptgroup, setDeptgroup] = useState();
	const [shifts, setShifts] = useState();
	const [roles, setRoles] = useState();
	const { api, logout } = useContext(AuthContext);
	const params = useParams();
	
    useEffect(() =>
    {
        const fetchCalendar = async () =>
        {
			console.log('Calendar::useEffect(fetchCalendar()) - ENTERING');
			console.log('calendarid: '+props.groupid);
			// console.log('getting for calendarID: '+params.groupid);
			api.get(
				process.env.REACT_APP_CALENDAR_ENDPOINT+props.groupid
				// "https://localhost:444/api/v1/calendar/"+props.groupid
			).then((result) =>
			{
                console.log('got:', result.data);
				setEvent(result.data.data.event);
				setDeptgroup(result.data.data.deptgroup);

				let map = new Map();

				if (result.data.data.shifts !== undefined)
				{
					for (const shift of result.data.data.shifts)
						map.set(shift.shiftid, shift);
				}
				setShifts(map);


				map = new Map();

				if (result.data.data.roles)
				{
					for (const role of result.data.data.roles)
						map.set(role.roleid, role);
				}
				setRoles(map);

				// setShifts(result.data.data.shifts);
				// setRoles(result.data.data.roles);
			})	
            // const result = await fetch('http://localhost:3333/events/7');
            // const result = await fetch('http://localhost/api/v1/events/14');
			/*
            const result = await fetch('http://localhost/api/v1/deptgroups?eventid=14');
			*/
			/*
			const result = await api.get('deptgroups?eventid=14');
			// Don't need the result.json call because Axios apparently does it automatically.. iliiike. verynice!
            // const jsondata = await result.json();
            setEvent(result);
            let tempMenuitems = [];
            //jsondata.d && jsondata.d.map((item) =>
			result.d && result.d.map((item) =>
            {
                tempMenuitems.push({itemname: item.groupname, itemlink: '/calendar/'+item.groupid});
            });
            // setMenuItems(tempMenuitems);
            props.menuCallback(tempMenuitems);
			*/
        }
        fetchCalendar()
        .catch(console.error);
    }, [ props.groupid ]);

    // const params = useParams();

	/*
    let dgobj = undefined;
    if (event.deptgroups && event.deptgroups.length)
        dgobj = event.deptgroups.find(dg => dg.groupid == params.groupid);
    dgobj && console.log('setting deptgroup to:',dgobj.groupname);
	*/
	useEffect(() =>
	{
		if (deptgroup !== undefined && deptgroup.groupname)
			parentItems.setBannerText(deptgroup.groupname);
			// parentItems.setBannerText('Calendar - '+deptgroup.groupname);
	}, [deptgroup]);

    return (
        // { eventObj && event.deptgroups && event.deptgroups.length && <DeptGroup deptgroup={dgobj} key={'dg-'+dgobj.groupid} start={event.eventstart} end={event.eventend} /> }
        // event && event.deptgroups && event.deptgroups.length && <DeptGroup deptgroup={dgobj} key={'dg-'+dgobj.groupid} start={event.eventstart.slice(0, -1)} end={event.eventend.slice(0, -1)} /> 
        // event && deptgroup && <DeptGroup deptgroup={deptgroup} shifts={shifts} key={'dg-'+props.groupid} start={event.eventstart.slice(0, -1)} end={event.eventend.slice(0, -1)} /> 
        <DeptGroup deptgroup={deptgroup} shifts={shifts} roles={roles} key={'dg-'+props.groupid} event={event} parentItems={props.parentItems} />
	)
}

export default Calendar;


