import '../CSS/DeptGroup.css';

import React, { useState, useEffect, useRef } from 'react';

import DeptGroupDate from './DeptGroupDate';

const { DateTime } = require("luxon");

// PROPS: deptgroup, shifts, event
function DeptGroup(props)
{
    const calByDateRef = useRef({});
    const calByDate = calByDateRef.current;


    const [ calData, setCalData ] = useState({});

	// console.log('deptgroup: ');
	// console.log(props.deptgroup);
	// console.log('shifts:');
	// console.log(props.shifts);
	// console.log('event:');
	// console.log(props.event);
	// console.log('shift length: '+(Object.keys(props.shifts).length));
	// if (!props.deptgroup || !Object.keys(props.deptgroup).length || !props.shifts || !Object.keys(props.shifts).length || !props.event || !Object.keys(props.event).length)
	if (!props.deptgroup || !Object.keys(props.deptgroup).length || !props.event || ! Object.keys(props.event).length || !props.shifts)
		return;

	const dg = props.deptgroup;
	const calStart = new Date(props.event.eventstart.slice(0, -1));
	const calEnd = new Date(props.event.eventend.slice(0, -1));

	/*
    useEffect(() =>
    {
		////// START OF COMMENTED OUT CODE (removed comment bc of outer comment block)
	    // Create empty objects for calByDate array that gets passed to individual components
	    for (let eventDate = new Date(calStart); eventDate <= calEnd; eventDate.setDate(eventDate.getDate() + 1))
	    {
	        // Initialize its shift object
	        const thisDate = new Date(eventDate);
	        const thisDateString = thisDate.yyyymmdd();
	        if (!(thisDateString in calByDate))
	            calByDate[thisDateString] = {};
	    }
		////// END OF COMMENTED OUT CODE (removed comment bc of outer comment block)
        // Loop through all shifts and sort by date
        if (!calData.length)
            return;
        for (const oneShift of calData)
        {
            const shiftStartString = new Date(oneShift.shift_start).yyyymmdd();
            // if (calByDate[shiftStartString] == undefined)
            if (!(shiftStartString in calByDate))
                calByDate[shiftStartString] = {};
            // if (calByDate[shiftStartString][oneShift['shiftid']] == undefined)
            if (!(oneShift['shiftid'] in calByDate[shiftStartString]))
                calByDate[shiftStartString][oneShift['shiftid']] = oneShift;
        }
        console.log('Finished sorting calByDate data:');
        console.log(calByDate);
    }, [ calData ]);
	*/
	/*
    console.log('calStart: ',calStart, props.start);
    console.log('calEnd: ',calEnd, props.end);
    console.log('Event runs for '+((calEnd - calStart)/(1000 * 3600 * 24))+' days.');
    console.log('Found '+calData.length+' shifts.');
	*/

	// Store avails per shift
	// for (const oneShift of props.shifts)
	props.shifts.forEach((oneShift, oneKey) =>
	{
		//WITHOUT_VIEW--> const shiftStartString = new Date(oneShift.shift_start).yyyymmdd();
		/*
		const startDate = DateTime.fromISO(oneShift.shift_start_date, { zone: 'UTC' });
		const dateObj = new Date(startDate);
		console.log('dateObj: ', dateObj);
		console.log('startDate: ', startDate);
		*/
		const shiftStartString = datetoYYYYMMDD(new Date(oneShift.shift_start_date));
		// const shiftStartString = startDate.yyyymmdd();
		// const shiftStartString = new Date(oneShift.shift_start_date).yyyymmdd();
		// if (calByDate[shiftStartString] == undefined)
		if (!(shiftStartString in calByDate))
			calByDate[shiftStartString] = {};
		// if (calByDate[shiftStartString][oneShift['shiftid']] == undefined)
		if (!(oneShift['shiftid'] in calByDate[shiftStartString]))
			calByDate[shiftStartString][oneShift['shiftid']] = oneShift;
	});
	// console.log('calByDate:');
	// console.log(calByDate);
	// Iterate through all dates and assign calinfo's date as the props
    let dates = [];
    for (let eventDate = new Date(calStart); eventDate <= calEnd; eventDate.setDate(eventDate.getDate() + 1))
    {
        // Initialize its shift object
        const thisDate = new Date(eventDate);
        // const thisDateString = thisDate.yyyymmdd();
		const thisDateString = datetoYYYYMMDD(thisDate);
		// console.log(thisDateString+': ', calByDate[thisDateString]);
        // if (!(thisDateString in calByDate))
        //     calByDate[thisDateString] = {};
        dates.push(
            <DeptGroupDate 
                key={'dgdate-'+dg.groupid+'-'+thisDateString} 
                keyName={'dgdate-'+dg.groupid+'-'+thisDateString}
                date={thisDate}
                shifts={calByDate[thisDateString] || {}}
				roles={props.roles}
				parentItems={props.parentItems}
            />);
    }

	// Below JSX includes the header for the groupname
    return (
		<div className='dgdates'>{dates}</div>
    )
    // return (
    //     <div className='deptgroup' key={'dgheader-'+dg.groupid} id={'dgheader-'+dg.groupid}>
    //         <h1 className='dgheader'>{dg.groupname}</h1>
    //         <div className='dgdates'>{dates}</div>
    //     </div>
    // )
}

function parseDate(i)
{
    var d = i.match(/(\d+)/g);
    return new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]);
}

function datetoYYYYMMDD(date)
{
	var mm = date.getUTCMonth() + 1; // getMonth() is zero-based
	var dd = date.getUTCDate();
  
	return [date.getUTCFullYear(),
			(mm>9 ? '' : '0') + mm,
			(dd>9 ? '' : '0') + dd
		   ].join('');
}

export default DeptGroup;