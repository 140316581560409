import React from 'react';
import ReactDOM from 'react-dom/client';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { AuthContextProvider } from './components/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
	<AuthContextProvider>
		<App />
	</AuthContextProvider>
  </React.StrictMode>
);


// NEW SPA version
/*
render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.querySelector('#root')
  </React.StrictMode>
)
*/


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
