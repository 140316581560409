import React, { createElement, useContext, useEffect, useState } from 'react';

function Banner(props)
{
    const [ bannerText, setBannerText ] = useState('');

    function parseText(text)
    {
        let parsedText = document.createElement('textarea');
        parsedText.innerHTML = text;
        return parsedText.value;
    }

    useEffect(() =>
    {
        setBannerText(props.text);
    }, [ props.text ]);

	if (!props.text || props.text == '')
		return null;
	return (
		<div className='banner'><h1>{parseText(bannerText)}</h1></div>
	);
}

export default Banner;