import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select'


function EventSelector({currentEvent, events, setEventChange})
{
	const [selectedEvent, setSelectedEvent] = useState(0);

	useEffect(() =>
	{
		// console.log('EventSelector::useEffect() - ENTERING');
		// console.log(events);
		if (events === undefined || Object.keys(events).length === 0 || typeof events !== 'object')
		{
			// console.log('EventSelector::useEffect() - events length 0');
			// console.log('type: '+(typeof events));
			return;
		}
		// console.log('EventSelector::useEffect() - events length: '+events.length);
		events.sort((a, b) => new Date(a.eventstart) - new Date(b.eventstart));
		const eventid = events[events.length - 1].eventid;
		// console.log('EVENTID: '+eventid);
		setSelectedEvent(eventid);
		setEventChange(eventid);
	}, [ events ] );

	useEffect(() =>
	{
		setEventChange(selectedEvent);
	}, [ selectedEvent ]);

	// console.log('events:');
	// console.log(events);
	if (!events || !events.length)
		return;
	
	return (
		// <select value={selectedEvent} onChange={(event) => setEventChange(event.target.value)}>
		<select value={selectedEvent} onChange={(event) => setSelectedEvent(event.target.value)}>
			{events.map((item) => ( <option key={item.eventid} value={item.eventid} selected={currentEvent && (item.eventid == currentEvent.eventid ? true : false)}>{item.eventname}</option>))}
		</select>
	);
}

export default EventSelector;
