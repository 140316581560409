import '../CSS/UserPage.css';

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";

import AuthContext from '../components/AuthContext';

export default function UserPage(props)
{
    const passwordDefault = '**PASSWORD_NO_CHANGE**';
	// props.parentItems.setBannerText('');
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
	const { api, user } = useContext(AuthContext);

    const [ deptInfo, setDeptInfo ] = useState({});
	const [ userinfo, setUserInfo ] = useState( {} );
    const [ passwordHidden, setPasswordHidden ] = useState(true);
	const params = useParams();

	useEffect(() =>
	{
		console.log('UserPage() - ENTERING for userid: '+user.userid);

		if (!user)
			return;
		api.get(
			// `users/${user.userid}?deptid=${params.id}`,
            'app?page=userpage&deptid='+params.id
		).then((result) =>
		{
            console.log('result.data: ', result.data);
            // console.log('result.data.data: ', result.data.data);
            // console.log('result.data.data.length: ', result.data.data.length);
			if (result.data && result.data.data)
            {
                result.data.data.password = passwordDefault;
                result.data.data.password2 = passwordDefault;
				setUserInfo(result.data.data);
                setDeptInfo({deptflags: result.data.data.deptflags});
            }
            if (result.data.data.deptinfo)
            {
                setDeptInfo(result.data.data.deptinfo);
                props.parentItems.setBannerText('Welcome to '+result.data.data.deptinfo.deptname);
            }

            props.parentItems.setBannerText('My Information');
        });
	}, []);

    function handleChange(e)
    {
        
        // console.log(userinfo);
        // console.log(e.target.name+':');
        console.log(e.target);
        if (userinfo[e.target.name] !== undefined)
            setUserInfo({...userinfo, [e.target.name]: e.target.value});
            // userinfo[e.target.name] = e.target.value;
        if (e.target.name == 'password' && passwordHidden)
        {
            setPasswordHidden((e.target.value === userinfo.password));
        }
    }

    function handleSubmit(e)
    {
        e.preventDefault();
		const payload = JSON.stringify(userinfo);
		api.put(
            `users/${user.userid}?deptid=${params.id}`,
            payload
        ).then((response) =>
		{
			console.log('submit returned:');
			console.log(response);
        });
    }

	return (
		<form className='deptPageBody'>
            <span className='userForm'>
                <div className='formgroupcolumn'>
	    		    <div className='formgroup'>
			    	    <div className='formgroupname'>Personal</div>
			    	    <label htmlFor='firstname'>First Name</label>
			    	    <input type='text' name='firstname' value={userinfo.firstname || ''} onChange={handleChange} />
			    	    <label htmlFor='lastname'>Last Name</label>
			    	    <input type='text' name='lastname' value={userinfo.lastname || ''} onChange={handleChange} />
			    	    <label htmlFor='phonenum'>Phone Number</label>
			    	    <input type='text' name='phonenum' value={userinfo.phonenum || ''} onChange={handleChange} />
			    	    <label htmlFor='address1'>Address 1</label>
			    	    <input type='text' name='address1' value={userinfo.address1 || ''} onChange={handleChange} />
			    	    <label htmlFor='address2'>Address 2</label>
			    	    <input type='text' name='address2' value={userinfo.address2 || ''} onChange={handleChange} />
			    	    <label htmlFor='city'>City</label>
			    	    <input type='text' name='city' value={userinfo.city || ''} onChange={handleChange} />
			    	    <label htmlFor='state'>State</label>
			    	    <input type='text' name='state' value={userinfo.state || ''} onChange={handleChange} />
			    	    <label htmlFor='zip'>Zip/Postal</label>
			    	    <input type='text' name='zip' value={userinfo.zip || ''} onChange={handleChange} />
			    	    <label htmlFor='country'>Country</label>
			    	    <input type='text' name='country' value={userinfo.country || ''} onChange={handleChange} />
			        </div>
                </div>
                <div className='formgroupcolumn'>
    			    <div className='formgroup'>
    			    	<div className='formgroupname'>Account</div>
                        <label htmlFor='nickname'>{(deptInfo.deptflags & 1) ? 'Display Name' : 'Nickname'}</label>
                        <input type='text' name='nickname' value={userinfo.nickname || ''} onChange={handleChange} disabled={(deptInfo.deptflags & 1)} />
                        <label htmlFor='email'>E-Mail</label>
                        <input type='text' name='email' value={userinfo.email || ''} onChange={handleChange} />
                        <label htmlFor='password'>Password</label>
                        <input type='password' name='password' value={userinfo.password || ''} onChange={handleChange} />
                        <label htmlFor='password2' style={{display: passwordHidden ? 'none' : 'block'}}>Password (Repeat)</label>
                        <input type='password' name='password2' value={userinfo.password2 || ''} onChange={handleChange} hidden={passwordHidden} />
                        <label htmlFor='oldpassword' style={{display: passwordHidden ? 'none' : 'block'}}>Existing Password</label>
                        <input type='password' name='oldpassword' value={userinfo.oldpassword || ''} onChange={handleChange} hidden={passwordHidden} />
    			    </div>
    			    <div className='formgroup'>
    			    	<div className='formgroupname'>Emergency Contact</div>
    			    	<label htmlFor='emergname'>Contact Name</label>
    			    	<input type='text' name='emergname' value={userinfo.emergname || ''} onChange={handleChange} />
    			    	<label htmlFor='emergnum'>Telephone Number</label>
    			    	<input type='text' name='emergnum' value={userinfo.emergnum || ''} onChange={handleChange} />
    			    	<label htmlFor='emergrel'>Relationship</label>
    			    	<input type='text' name='emergrel' value={userinfo.emergrel || ''} onChange={handleChange} />
    			    </div>
                </div>
            </span>
            <input type='submit' name='update' value='Update' onClick={handleSubmit} />
		</form>
	)
}