import '../CSS/DeptPageWrapper.css';

import React, { useContext, useEffect, useState } from 'react';
// import { defaultProps } from 'react-select/dist/declarations/src/Select';
import { useParams } from 'react-router-dom';
import AuthContext from '../components/AuthContext.js';

// import Calendar from '../components/Calendar.js';
import {
    Outlet
	// Link,
	// useLocation,
	// useParams,
	// unstable_HistoryRouter
} from 'react-router-dom';

export default function PageWrapper(props)
{
	const { api, logout, user, extra }	 = useContext(AuthContext);
	// const [roles, setRoles] = useState({});
	// const [calendar, setCalendar] = useState({});
	// const [menuItems, setMenuItems] = useState([]);
    const [showPage, setShowPage] = useState(false);

	const params = useParams();

    //
	// MENU RENDERER
	//

	function buildMenu(values)
	{
		// console.log('buildMenu() - ENTERING');

        values.userid = user.userid || -999;

		let menu = 
		{
			"Home":
			{
				link:	`/dept/${values.deptinfo.deptid}`,
                order:  1
			},
			"User Settings":
			{
				children:
				{
					"My Info":
					{
						link:	`/dept/${values.deptinfo.deptid}/myinfo`
					},
					"My Schedule":
					{
						link:	`/dept/${values.deptinfo.deptid}/myschedule`
					}
				},
                order:  2
			},
            "Change Groups":
            {
                link:   "/",
                order: 4
            },
            "Logout":
            {
                function: () => { logout(); },
                order: 9999999999
            }
		}

		if (values.deptgroups)
		{
			const calmenu = {};
			for (const onedg of values.deptgroups)
				calmenu[onedg.deptgroupname] = { link: `/dept/${values.deptinfo.deptid}/calendar/${onedg.deptgroupid}` };
            if (Object.keys(calmenu).length)
                menu['Calendars'] = { children: calmenu, order: 3 };
            props.parentItems.setMenu(menu);
		}
	}

    useEffect(() =>
    {
        const deptid = parseInt(params.id);

        // Build initial menu
        if (user)
        {
            props.parentItems.setMenu({"Logout": { order: 9999999999, function: () => { logout(); } }});
        }
        else
        {
            props.parentItems.setMenu(
            {
                "Home":     { link: `/dept/${deptid}` },
                "Login":    { function: () => { logout(); } }
            });
        }
        
        api.get(
			"app?page=dept&deptid="+deptid
			// payload
		).then((result) =>
		{
			// console.log('got result:');
			// console.log(result);
			// setDepts(result.data.data);
            if (result && result.data && result.data.data)
            {
                extra.deptPage = result.data.data;
                buildMenu(extra.deptPage);
            }
        });
        setTimeout(() => setShowPage(true), 100);
    }, []);

    /*
    useEffect(() =>
    {
        // Check if the page has already loaded
        // if (document.readyState === 'complete')
        {
            // console.log('calling setTimer()..');
            setTimeout(() => setShowPage(true), 10);
        }
    } []);
    // }, [document.readyState]);
    */

    const cover = 
    {
        position: 'fixed',
        height: '100%',
        width: '100%',
        top:'0',
        left: '0',
        background: '#000',
        zIndex: '9999'
    }

    return (
        <div><div style={cover} hidden={showPage} /><Outlet /></div>
    );
}