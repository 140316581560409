// import logo from './logo.svg';
import './CSS/App.css';
import React, { useContext, useEffect, useState } from 'react';

import {
	BrowserRouter as Router,
	Routes,
	Route
	// Link,
	// useLocation
	// useParams,
	// unstable_HistoryRouter
} from 'react-router-dom';

import Sidebar from './components/Sidebar';
// import Calendar from './components/Calendar';
import Banner from './components/Banner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContextProvider } from './components/AuthContext';
import AuthContext from './components/AuthContext';

import CalPage from './pages/CalPage.js';
import DeptPage from './pages/DeptPage';
import DeptPageMySchedule from './pages/DeptPageMySchedule';
import HomePage from './pages/HomePage.js';
import MyInfo from './pages/UserPage.js';

import DeptPageWrapper from './pages/DeptPageWrapper.js';

// const cssBase = 'https://localhost:8443/CSS/ReactCSS/';
const cssBase = process.env.REACT_APP_CSS_BASE;



/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/

Date.prototype.yyyymmdd = function()
{
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();
  
	return [this.getFullYear(),
			(mm>9 ? '' : '0') + mm,
			(dd>9 ? '' : '0') + dd
		   ].join('');
};

function App()
{
    const currentURL = window.location.pathname;
	const [menuItems, setMenuItems] = useState({});
	const [bannerText, setBannerText] = useState("");
    const [interBannerText, setInterBannerText] = useState('');
    const [additionalCSS, setAdditionalCSS] = useState({});
    const [deptInfo, setDeptInfo] = useState({});

    const setSetDeptInfo = (val) => { this.setDeptInfo(val); console.log('val:', val) };
	const parentItems =
	{
		setMenu:			setMenu,
		setMenuItem:		setMenuItem,
		setBannerText:		setInterBannerText,
		addNotification:	addNotification,
		removeNotification:	removeNotification,
		// buildMenu:			buildMenu,
        setAdditionalCSS:   setAdditionalCSS,
        deptInfo:           deptInfo,
        setDeptInfo:        setSetDeptInfo
	};

    const { api, login, logout, user }	 = useContext(AuthContext);

    //
    // BANER FUNCTION
    //
    useEffect(() =>
    {
        setBannerText(interBannerText);
    }, [interBannerText]);

	//
	// NOTIFICATION FUNCTIONS
	//

	function addNotification(type, message, callback)
	{
		switch (type.toLowerCase())
		{
		case 'error':
			toast.error(message,
			{
				// duration: Infinity
			});
			break;
		default:
			toast(message);
			break;
		}
	}

	function removeNotification(type, message)
	{

	}

	//
	// MENU FUNCTIONS
	//

	function setMenuItem(item, children)
	{
		const values = { item: item, children: children };
		// console.log('App.js::setMenuItem() - ENTERING for '+item);
		setMenuItems((oldMenu) =>
		{
			// console.log('App.js::setMenuItems anonymous function ENTERING for '+item);
			// console.log('values:');
			// console.log(values);
			// console.log('oldMenu:');
			// console.log(oldMenu);
			let menu =
			{
				...oldMenu,
				[values.item]: values.children
			};
			// If children is null or undefined, we delete that key of the tree
			if (values.children === null || values.children === undefined)
			{
				delete menu[item];
				// delete testMenuItems[item];
			}
			// console.log('newMenu:');
			// console.log(menu);
			return menu;
			// setMenuItems(menu);
		});
	/*
		console.log('calling setMenuItems with: ');
		console.log(testMenuItems);
		setMenuItems(testMenuItems);
		*/
	}

	function setMenu(menu)
	{
		// console.log('App.js::setMenu() - ENTERING', menu);
		const values = { menu: menu };
		setMenuItems((oldMenu) =>
		{
			// console.log('calling setMenu(anonymous) with:');
			// console.log('oldMenu:');
			// console.log(oldMenu)
			// console.log('newMenu;');
			// console.log(values.menu);
			return values.menu;
		});
		// setMenuItems(menu);
	}

	//
	// MENU RENDERER
	//

	function buildMenu(pageName, values)
	{
		console.log('buildMenu('+pageName+') - ENTERING');

		switch (pageName)
		{
		default:
			break;
		case "DeptPage":
		case "Calendar":
			let menu = 
			{
				"Home":
				{
					link:	`/dept/${values.id}`
				},
				"User Settings":
				{
					children:
					{
						"My Schedule":
						{
							link:	`/dept/${values.id}/myschedule`
						},
						"My Info":
						{
							link:	`/dept/${values.id}/myinfo`
						}
					}
				}
			}
			if (values.deptgroups)
			{
				const calmenu = {};
				for (const onedg of values.deptgroups)
					calmenu[onedg.deptgroupname] = { link: `/dept/${values.id}/calendar/${onedg.deptgroupid}` };
				setMenuItem('Calendars', { children: calmenu });
			}
		// eslint-disable-next-line no-fallthrough
		case "lastone":
			for (const menuItem in menu)
				setMenuItem(menuItem, menu[menuItem]);
			break;
		}
	}

	useEffect(() =>
	{
		// console.log('App.js () - ENTERING useEffect for Menu');
		// console.log(menuItems);
	}, [ menuItems ]);

    //
    // CSS Functions
    //

    const addCSSToPage = ((cssLink) =>
    {
        const docHead = document.head;
        const newLink = document.createElement('link');
        newLink.href = cssBase+cssLink;
        if (!newLink.href.endsWith('.css'))
            newLink.href += '/dept.css';
        newLink.rel = 'stylesheet';
        newLink.title = 'babaCSS';
        newLink.type = 'text/css';
        console.log('newLink:', newLink);
        docHead.appendChild(newLink);
    });

    useEffect(() =>
    {
        // console.log('useEffect() for additionalCSS - ENTERING');
        Object.keys(additionalCSS).forEach((item) => addCSSToPage(additionalCSS[item]));
    }, [ additionalCSS ]);

    useEffect(() =>
    {
        // console.log('useEffect() history for App.js - ENTERING');
        // const path = window.location.pathname.split('/');
        if (typeof currentURL !== 'string')
            return;
        // console.log('got URL: '+currentURL);
        const path = currentURL.split('/');
        if (path.length >= 3 && path[1] === 'dept')
        {
            const deptid = parseInt(path[2]);
            // console.log('got dept: ', deptid);
            api.get(
				"app?page=dept&deptid="+deptid
				// payload
			).then((result) =>
            {
                setAdditionalCSS([ result.data.data.css[0], result.data.data.hash ]);
            });
        }
        // const paramValue = params.get("post");
    }, [ currentURL ]);

    //
    //
    //
	
	return (
		<div className='pageWrapper'>
            <Banner text={bannerText} parentItems={parentItems} />
			<ToastContainer />
			<div className="Page">
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} menu={menuItems} parentItems={parentItems} />
				<div className='bodyWrapper'><div className='Body'>
					<Router>
						<Routes>
							<Route path="/"                         element={<HomePage              parentItems={parentItems} />} />
							<Route path="/dept/:id"                 element={<DeptPageWrapper       parentItems={parentItems} />} >
                                <Route index 						element={<DeptPage 				parentItems={parentItems} />} />
							    <Route path="myinfo"                element={<MyInfo                parentItems={parentItems} />} />
                                <Route path="myschedule" 			element={<DeptPageMySchedule 	parentItems={parentItems} />} />
								<Route path="calendar/:groupid" 	element={<CalPage 				parentItems={parentItems} />} />
							</Route>
						</Routes>
					</Router>
				</div></div>
			</div>
		</div>
	)
}  

export default App;
