import React from 'react';
import DeptGroupAvails from './DeptGroupAvails';

import '../CSS/DeptGroupShift.css';

function DeptGroupShift(props)
{
    const shift = props.shift;
	const avails = [];
	if (shift && shift.availshifts)
		shift.availshifts.forEach( (a) => avails[a.availid] = a );

    // console.log('FOUND '+avails.length+' avails for shiftid: '+shift.shiftid);
    // let avails = props.shift.avails.map((oneavail) =>
    // {
    //     console.log('avail:',oneavail);
    // })

    return (
        // <div key={'shift-'+shift.shiftid} className='dgshift'>
        <div className='dgshift'>
            <div className='dgshiftheader'>{calTimeString(shift.shift_start_date.slice(0, -1), shift.shift_end_date.slice(0, -1))}</div>
            <DeptGroupAvails avails={avails} shift={shift} roles={props.roles} key={'dgavails-shiftid-'+shift.shiftid} id={'shift-'+shift.shiftid} parentItems={props.parentItems} />
        </div>
    )

}

export default DeptGroupShift;

function calTimeString(s,e)
{
    return getTime(s)+' - '+getTime(e);
}

function getTime(e, rawtime)
{
    if (rawtime === true)
        return ("0" + e.getHours()).slice(-2) + ("0" + e.getMinutes()).slice(-2);
    var ap;
    var d = new Date(e);
    if (d.getHours() > 12)
        return (d.getHours() - 12)+':'+("0"+d.getMinutes()).slice(-2)+'pm';
    else if (d.getHours() == 12)
    {
        if (d.getMinutes() == 0)
            return "Noon";
        return "12:"+("0"+d.getMinutes()).slice(-2)+'pm';
    }
    else if (d.getHours())
        return d.getHours()+':'+("0"+d.getMinutes()).slice(-2)+'am';
    if (d.getMinutes() == 0)
        return "Midnight";
    return '12:'+("0"+d.getMinutes()).slice(-2)+'am';
}

function dateFromTimeZone(date, timeZone)
{
    if (typeof date === 'string')
    {
        return new Date(
            new Date(date).toLocaleString('en-US', { timeZone })
        );
    }

    return new Date(
        date.toLocaleString('en-US', { timeZone })
    );
}